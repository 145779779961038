import { interfaceConstants } from "../constants";

export function toggleSidebar() {
  return { type: interfaceConstants.SIDEBAR_TOGGLE };
}
export function openSidebar() {
  return { type: interfaceConstants.SIDEBAR_OPEN };
}
export function closeSidebar() {
  return { type: interfaceConstants.SIDEBAR_CLOSE };
}
