import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import CssBaseline from "@material-ui/core/CssBaseline";

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#F4F4F4",
      light: "#FFFFFF",
      dark: grey[150]
    },
    secondary: {
      main: "#3683DC",
      light: "#368DC",
      dark: "#549AEC"
    },
    third: {
      main: "#222222",
      light: "#424242",
      dark: "#000000"
    },
    divider: "#222222",
    background: {
      paper: "#32363C",
      default: "#2f3236"
    }
  },
  sidebar: {
    width: 214,
    bottomBorderColor: "rgba(0, 0, 0, 0.12)",
    itemColor: "#C9CACB",
    activeItemColor: "#FFFFFF",
    selectedBackgroundColor: "rgba(0, 0, 0, 0.1)",
    hoverBackgroundColor: "rgba(0, 0, 0, 0.08)"
  },
  shadows: Array(25).fill("none")
});

if (process.env.NODE_ENV === "development") {
  console.log(theme);
}

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
