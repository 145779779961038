import moment from 'moment'

export const convertDateStringFromFormatToLocalFormat = (inputDateString, outputFormat, inputDateTimezone = null, inputFormat = null) => {
  try {
    let momentDate;
    if (inputFormat) {
      momentDate = moment(inputDateString, inputFormat);
    } else {
      momentDate = moment(inputDateString);
    }
    if (inputDateTimezone) {
      momentDate = momentDate.tz(inputDateTimezone);
    }
    return momentDate.local().format(outputFormat);
  } catch (err) {
    return "";
  }
};

export const formatMYSQLDate = (date) => {
    return convertDateStringFromFormatToLocalFormat(date, "MMM D, YYYY [at] h:mm A");
};

export const sortableFormattedMYSQLDate = (date) => {
  return convertDateStringFromFormatToLocalFormat(date, "YYYY-MM-DD [at] HH:mm");
};

export default {
    convertDateStringFromFormatToLocalFormat,
    formatMYSQLDate,
    sortableFormattedMYSQLDate
  };
  