import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MUITableBody from "@material-ui/core/TableBody";

const styles = theme => ({
    tableBody: {
        
    }
});

const TableBody = (props) => {
    return <MUITableBody className={props.classes.tableBody}>{props.children}</MUITableBody>;
}

export default withStyles(styles)(TableBody);