import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MUITableHeaderCell from "@material-ui/core/TableCell";

const styles = theme => ({
    tableHeaderCell: {
        color: theme.palette.primary.light,
        fontSize: '0.9rem'
    }
});

const TableHeaderCell = (props) => {
    return <MUITableHeaderCell className={props.classes.tableHeaderCell}>{props.children}</MUITableHeaderCell>;
}

export default withStyles(styles)(TableHeaderCell);