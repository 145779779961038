import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MUITableRow from "@material-ui/core/TableRow";

const styles = theme => ({
  tableRow: {
    borderLeft: `10px solid transparent`,
    "&:hover": {
      borderLeft: `10px solid ${theme.palette.secondary.main}`,
      backgroundColor: "rgba(0, 0, 0, 0.10)"
    },
    transition:
      "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    transitionProperty: "background-color, border-color",
    transitionDuration: "300m",
    transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
    transitionDelay: "0m"
  }
});

const TableRow = props => {
  return (
    <MUITableRow className={props.classes.tableRow} style={props.style} onClick={props.onClick}>
      {props.children}
    </MUITableRow>
  );
};

export default withStyles(styles)(TableRow);
