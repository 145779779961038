//import React from "react";

import Dashboard from "../containers/Dashboard";
import Purchases from "../containers/Purchases";
import Account from "../containers/Account";
import Devices from "../containers/Devices";
import PromoCodes from "../containers/PromoCodes";
import PromoCodesByUsers from "../containers/PromoCodesByUsers";
import FindUsers from "../containers/FindUsers";
import Delete from "../containers/Delete";

export const basePath = process.env.REACT_APP_BASE_PATH;

export const routes = [
  {
    key: "dashboard",
    title: "Dashboard",
    path: `${basePath}/`,
    exact: true,
    component: Dashboard,
    roles: [1, 2]
  },
  {
    key: "purchases",
    title: "Purchases",
    path: `${basePath}/purchases`,
    component: Purchases,
    roles: [1, 2]
  },
  {
    key: "devices",
    title: "Devices",
    path: `${basePath}/devices`,
    component: Devices,
    roles: [1, 2]
  },
  {
    key: "account",
    title: "Account",
    path: `${basePath}/account`,
    component: Account,
    roles: [1, 2]
  },
  {
    key: "find",
    title: "Find Users",
    path: `${basePath}/find`,
    component: FindUsers,
    roles: [1],
    section: 1
  },
  // {
  //   key: "delete",
  //   title: "Delete Users",
  //   path: `${basePath}/delete`,
  //   component: Delete,
  //   roles: [1],
  //   section: 1
  // },
  {
    key: "codes",
    title: "Promo Codes",
    path: `${basePath}/codes`,
    component: PromoCodes,
    roles: [1],
    section: 1
  },
  {
    key: "users-codes",
    title: "Users Codes",
    path: `${basePath}/users-codes`,
    component: PromoCodesByUsers,
    roles: [1],
    section: 1
  }
];

export const routesForRole = (role) => {
  return routes.filter(route => route.roles.includes(role));
}


export const createSectionsFromRoutes = (theRoutes) => {

  let highestSectionIndex = 0;
  theRoutes.forEach(route => {
    const sectionIndex = route.section && typeof route.section === "number" ? route.section : 0;
    if (sectionIndex > highestSectionIndex) highestSectionIndex = sectionIndex;
  });

  const sections = [];
  for (let i = 0; i <= highestSectionIndex; i++) {
    sections[i] = [];
  }

  theRoutes.forEach(route => {
    const sectionIndex = route.section && typeof route.section === "number" ? route.section : 0;
    sections[sectionIndex].push(route);
  });

  return sections;
}


// Helpers

export const routeForKey = (key) => {
  routes.forEach(route => {
    if (route.key === key) return key;
  });
};

const placeParamsInRoutePath = (pathRegex, params) => {
  var segments = pathRegex.split("/");
  return segments.map(segment => {
    var offset = segment.indexOf(":") + 1;
    if (!offset)
      return segment;
    var key = segment.slice(offset);
    return params[key];
  }).join("/");
};

export const routePathForKey = (key, params = null) => {
  let routePathValue = null;
  routes.forEach(route => {
    if (route.key === key) {
      routePathValue = params ? placeParamsInRoutePath(route.path, params) : route.path;
    }
  });
  return routePathValue;
};