import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MUITableHead from "@material-ui/core/TableHead";

const styles = theme => ({
    tableHead: {
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
    }
});

const TableHead = (props) => {
    return <MUITableHead className={props.classes.tableHead}>{props.children}</MUITableHead>;
}

export default withStyles(styles)(TableHead);