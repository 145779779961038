const configs = {
  WEB_URL: process.env.REACT_APP_WEB_URL,
  LOGIN_URL: process.env.REACT_APP_LOGIN_URL,
  LOGOUT_URL: process.env.REACT_APP_LOGOUT_URL,
  API_URL: process.env.REACT_APP_API_URL,

  APT_URL: process.env.REACT_APP_APT_URL,
  APT_API_URL: process.env.REACT_APP_APT_API_URL
};

export default Object.freeze(configs);
