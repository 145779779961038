import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class PageLoader extends Component {
  render() {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <CircularProgress color="secondary" style={{ width: "100px", height: "100px" }} />
      </div>
    );
  }
}
