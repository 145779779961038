import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";

import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";


import MenuIcon from "@material-ui/icons/Menu";

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';

import config from "../../config";

const styles = theme => ({
  appBar: {
    marginLeft: theme.sidebar.width,
    height: "64px"
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: {
    display: "flex",
    height: "100%",
    justifyContent: "space-between"
  },
  appBarLeftContainer: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.sidebar.width
    }
  },
  appBarTitle: {
  },
  menuItem: {
    //boxShadow: "0 0 5px #222"
    //backgroundColor:"yellow"
  }
});

class NavBar extends React.Component {
  state = { accountMenuAnchorEl: null }

  uploadButtonTapped = () => {
    this.uploadDialogToggleAction();
  }

  handleChange = event => {
    //this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ accountMenuAnchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ accountMenuAnchorEl: null });
  };

  handleLogout = () => {
    this.handleClose();
    window.location = config.LOGOUT_URL;
  };

  render() {
    const { classes, username, sidebarToggleAction } = this.props;
    const { accountMenuAnchorEl } = this.state;
    const open = Boolean(accountMenuAnchorEl);

    return (
      <AppBar position="fixed" color="inherit" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
        <div className={classes.appBarLeftContainer}>
          <IconButton
              color="primary"
              aria-label="Open drawer"
              onClick={sidebarToggleAction}
              className={classes.menuButton}
            >
            <MenuIcon />
          </IconButton>
        </div>

        <div>
          <Hidden smUp>
            <IconButton
              aria-owns={open ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="primary"
            >
              <AccountCircle />
            </IconButton>
          </Hidden>

          <Hidden xsDown>
            <Button
              variant="text"
              size="small"
              color="primary"
              className={classes.createButton}
              onClick={this.handleMenu}
              style={{borderRadius: "0", textTransform: "none"}}
            >
              <AccountCircle /> &nbsp;&nbsp; {username}
            </Button>
          </Hidden>

          <Menu
            id="menu-appbar"
            className={classes.menu}
            anchorEl={accountMenuAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={this.handleClose}
          >
            <MenuItem onClick={this.handleClose}>My Account</MenuItem>
            <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
          </Menu>
          </div>

        </Toolbar>
      </AppBar>
    );
  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NavBar);
