import api from "../services/api";
import { authConstants } from "../constants";
import jwt from "jsonwebtoken";
import config from "../config";

export function validateAuth() {
  return async (dispatch) => {
    // console.log("[Auth]: Saving params to Cookies if needed");
    const user = await validateCookiesAuth();
    if (user) {
      // console.log("[Auth]: cookies auth validated");
      dispatch({ type: authConstants.UPDATE_LOGGED_IN, loggedIn: true, user: user });
    } else {
      // console.log("[Auth]: cookies auth NOT validated");
      window.location = `${config.LOGIN_URL}?redirect=${window.location.href}`;
    }
  };
}

async function validateCookiesAuth() {
  try {
    const cookie_access_token = api.getCookie("access_token");
    const cookie_refresh_token = api.getCookie("refresh_token");
    // console.log(`[Auth]: Cookies Access Token: ${cookie_access_token}`);
    // console.log(`[Auth]: Cookies Refresh Token: ${cookie_refresh_token}`);
    if (cookie_access_token && cookie_refresh_token) {
      const res = await api.Auth.refresh(cookie_refresh_token);
      if (res.access_token) {
        const user = jwt.decode(res.access_token);
        if (user) {
          // const now = Math.floor(Date.now() / 1000);
          // console.log(`[Auth][UPDATE_ACCESS_TOKEN]: Access Token Expires in ${(user.exp-now)} seconds or ${(user.exp-now)/60} minutes`);
          api.setCookie("access_token", res.access_token);
          api.setUser(user);
          api.setAccessToken(res.access_token);
          api.setRefreshToken(cookie_refresh_token);
          return user;
        }
      }
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
}