import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import PageTitle from "../components/PageTitle";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';

import CircularProgress from '@material-ui/core/CircularProgress';

import { validateAuth } from "../actions/auth.actions";
import api from "../services/api";

const styles = theme => {
  return {
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing.unit,
      marginLeft: "0",
      marginRight: "25px",
    },
    formLabel: {
      marginBottom: "5px"
    },
    separatorLine: {
      backgroundColor: theme.palette.third.main,
      height: "1px",
      marginTop: "-1px"
    },
    tabItem: {
      "&:hover": {
        textColor: theme.palette.secondary.main
      }
    },
    submitButton: {
      borderRadius: 0,
      textTransform: "none",
      height: "37px",

      margin: theme.spacing.unit,
      marginTop: "0"
    }
}};

function TabContainer(props) {
  return (
    <Typography component="div" style={{ paddingTop: 24 }}>
      {props.children}
    </Typography>
  );
}

class Account extends Component {

  state = {
    tabIndex: 0,
    firstname: this.props.auth.user.firstname?this.props.auth.user.firstname:"", lastname: this.props.auth.user.lastname?this.props.auth.user.lastname:"",
    password: "", new_password: "", logout: false,
    profileLoading: false, passwordLoading: false,
  };

  componentDidMount () {
    document.title = "Account";
  }

  handleTabChange = (event, value) => {
    this.setState({ tabIndex: value, profileMessage: null ,profileError: null, securityMessage: null, securityError: null });
  };

  handleInputChange = event => {
    this.setState({ [event.target.id]: event.target.id === "logout" ? event.target.checked : event.target.value });
  };

  handleProfileSubmit = async (event) => {
    this.setState({ profileLoading: true });
    const { firstname, lastname } = this.state;
    if (!firstname || firstname.length < 1) {
      this.setState({ profileLoading: false, profileError: "Firstname can not be empty"});
      return;
    }
    try {
      await api.User.updateProfile(firstname, lastname);
      await this.props.dispatch(validateAuth());
      this.setState({ profileLoading: false, profileError: null, profileMessage: "Profile updated successfully"});
    } catch(err) {
      console.log(err);
      this.setState({ profileLoading: false, profileError: "Profile update failed "});
    }
  };

  handlePasswordSubmit = async (event) => {
    this.setState({ securityLoading: true });
    const { password, new_password, logout } = this.state;
    if (!password || password.length < 1) {
      this.setState({ securityLoading: false, securityError: "Current password is required"});
      return;
    }
    if (!new_password) {
      this.setState({ securityLoading: false, securityError: "New password can not be empty"});
      return;
    }
    if (new_password.length < 6) {
      this.setState({ securityLoading: false, securityError: "New password must be more than 6 characters"});
      return;
    }
    try {
      await api.User.updatePassword(password, new_password, logout);
      await this.props.dispatch(validateAuth());
      this.setState({ securityLoading: false, securityError: null, securityMessage: "Password changed successfully"});
    } catch(err) {
      console.log(err);
      const errorBody = err.response && err.response.data ? err.response.data.error : null;
      const message = errorBody && errorBody.message && errorBody.message === "WRONG_PASSWORD" ? "Incorrect current password" : "Password change failed";
      this.setState({ securityLoading: false, securityError: message});
    }
  };

  render() {
    const { classes } = this.props;
    const { tabIndex, 
      profileLoading, profileError, profileMessage, firstname, lastname,
      securityLoading, securityError, securityMessage, password, new_password, logout
    } = this.state;

    return (
      <React.Fragment>

      <PageTitle title="Account" />
      
      <Tabs value={tabIndex} onChange={this.handleTabChange} textColor="primary" indicatorColor="secondary" disabled={profileLoading || securityLoading}>
        <Tab label="Profile" className={classes.tabItem} disabled={profileLoading || securityLoading} />
        <Tab label="Security" className={classes.tabItem} disabled={profileLoading || securityLoading} />
      </Tabs>

      <div className={classes.separatorLine}></div>
        {tabIndex === 0 && 
        <TabContainer>
          <Card className={classes.card}>
            <CardContent>

            <form className={classes.form} noValidate autoComplete="off">
                <input type="text" autoComplete="username" style={{display: "none"}}/>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend" className={classes.formLabel}>Update your profile</FormLabel>
                  <FormGroup row>
                    <FormControl className={classes.formControl}>
                      <InputLabel required htmlFor="firstname">Firstname</InputLabel>
                      <Input id="firstname" value={firstname} onChange={this.handleInputChange} disabled={profileLoading} />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="lastname">Lastname</InputLabel>
                      <Input id="lastname" value={lastname} onChange={this.handleInputChange} disabled={profileLoading} />
                    </FormControl>
                  </FormGroup>
                </FormControl>
              </form>

            </CardContent>
            <CardActions>
              <Button variant="contained" size="large" color="secondary" className={classes.submitButton} onClick={this.handleProfileSubmit} disabled={profileLoading} >Save</Button>
              {profileLoading && <CircularProgress size={30} className={classes.progress} color="secondary" style={{marginLeft: "15px"}} />}
              {!profileLoading && profileError && profileError.length > 0 && <Typography component="div" color="error">{profileError}</Typography>}
              {!profileLoading && profileMessage && profileMessage.length > 0 && <Typography component="div" color="secondary">{profileMessage}</Typography>}
            </CardActions>
          </Card>
        </TabContainer>}
        
        {tabIndex === 1 && 
        <TabContainer>
          <Card className={classes.card}>
            <CardContent>
              <form className={classes.form} noValidate autoComplete="off">
                <input type="text" autoComplete="username" style={{display: "none"}}/>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend" className={classes.formLabel}>Change your password</FormLabel>
                  <FormGroup row>
                    <FormControl className={classes.formControl}>
                      <InputLabel required htmlFor="password">Current Password</InputLabel>
                      <Input type="password" id="password" value={password} onChange={this.handleInputChange} autoComplete="password" disabled={securityLoading} />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel required htmlFor="new_password">New Password</InputLabel>
                      <Input type="password" id="new_password" value={new_password} onChange={this.handleInputChange} autoComplete="new-password" disabled={securityLoading} />
                    </FormControl>
                  </FormGroup>
                  <FormGroup row>
                    <FormControlLabel label="Logout Devices" control={
                      <Checkbox id="logout" checked={logout} onChange={this.handleInputChange} color="primary" />
                    }/>
                  </FormGroup>
                  <FormHelperText>Enabling this option will logout your account from all your active devices</FormHelperText>
                </FormControl>
              </form>
            </CardContent>
            <CardActions>
              <Button variant="contained" size="large" color="secondary" className={classes.submitButton} onClick={this.handlePasswordSubmit} disabled={securityLoading} >Change</Button>
              {securityLoading && <CircularProgress size={30} className={classes.progress} color="secondary" style={{marginLeft: "15px"}} />}
              {!securityLoading && securityError && securityError.length > 0 && <Typography component="div" color="error">{securityError}</Typography>}
              {!securityLoading && securityMessage && securityMessage.length > 0 && <Typography component="div" color="secondary">{securityMessage}</Typography>}
            </CardActions>
          </Card>
        </TabContainer>}

      </React.Fragment>
    )
  }
}

Account.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(withStyles(styles)(Account));
