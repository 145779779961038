import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
  paperStyle: {
    // width: "100%",
    // marginTop: theme.spacing.unit * 3,
    // overflowX: "auto",
    // borderRadius: "0px",
    // backgroundColor: theme.palette.background.default
    borderRadius: 0,
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  }
});

const PageContent = props => {
  const { classes, children, maxWidth, ...otherProps } = props;
  return (
    <Paper className={props.classes.paperStyle} style={maxWidth?{"maxWidth":maxWidth}:null} {...otherProps}>
      {children}
    </Paper>
  );
};

export default withStyles(styles)(PageContent);
