import React, { Component } from 'react'
import { connect } from "react-redux"
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import PageTitle from "../components/PageTitle";
import PageLoader from "../components/PageLoader";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
//import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import API from "../services/api";
import dateFormatter from "../services/formatters/date"

import MUIDataTable from "mui-datatables";

import GenerateCodesDialog from '../components/Dialogs/GenerateCodesDialog';

const availableTableColumns = [
  { name: "Code", options: { filter: true, sort: true } },
  { name: "Product", options: { filter: true, sort: true } },
  { name: "Date Created", options: { filter: true, sort: true } },
  { name: "Desc", options: { filter: true, sort: true } },
];

const redeemedTableColumns = [
  { name: "Code", options: { filter: true, sort: true } },
  { name: "Product", options: { filter: true, sort: true } },
  { name: "Redeemed By", options: { filter: true, sort: true } },
  { name: "Date Created", options: { filter: true, sort: true } },
  { name: "Date Redeemed", options: { filter: true, sort: true } },
  { name: "Desc", options: { filter: true, sort: true } },
 ];

const options = {
  filterType: 'checkbox',
  responsive: "scroll"
};

const styles = theme => ({
  submitButton: {
    borderRadius: 0,
    textTransform: "none",
    height: "37px",

    margin: theme.spacing.unit,
    marginTop: "0"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  separatorLine: {
    backgroundColor: theme.palette.third.main,
    height: "1px",
    marginTop: "-1px"
  },
  tabItem: {
    "&:hover": {
      textColor: theme.palette.secondary.main
    }
  },
});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ paddingTop: 24 }}>
      {props.children}
    </Typography>
  );
}

const defaultState = {
  loading: false,
  error: null,
  codesByUser: null,
  expandedUserID: null,
  tabIndexes: {},
  generateDialogOpen: false
};

class PromoCodes extends Component {
  state = defaultState;

  componentDidMount () {
    document.title = "Users Codes";
  }

  async loadData() {
    try {
      const codesByUser = await API.Admin.promoCodesByUser();
      this.setState({ loading: false, error: null, codes: codesByUser });
    } catch (err) {
      this.setState({ loading: false, error: "Promo Codes Loading Failed" });
    }
  }

  async componentWillMount() {
    await this.loadData();
  }

  handleUserSectionExpanded = panel => (event, expanded) => {
    this.setState({ expandedUserID: expanded ? panel : false });
  };

  tableDataFromCodes = (codes) => {
    return codes.map(code => {
      const formattedDate = dateFormatter.sortableFormattedMYSQLDate(code.created_at);
      return [ code.id, code.product_name, formattedDate, code.desc ]
    });
  }

  tableDataFromRedeemedCodes = (codes) => {
    return codes.map(code => {
      const formattedCreatedDate = dateFormatter.sortableFormattedMYSQLDate(code.created_at);
      const formattedDate = dateFormatter.sortableFormattedMYSQLDate(code.redeemed_at);
      const redeemedBy = `${code.redeemedBy.firstname}${code.redeemedBy.lastname?code.redeemedBy.lastname:''} | ${code.redeemedBy.email}`;
      return [ code.id, code.product_name, redeemedBy, formattedCreatedDate, formattedDate, code.desc ]
    });
  }


  handleGenerateButtonTap = () => {
    this.setState({ generateDialogOpen: true });
  };

  handleGenerateDialogClose = () => {
    this.setState({ generateDialogOpen: false });
  }
  
  render() {
    const {
      error, loading, codes, expandedUserID, tabIndexes, generateDialogOpen
    } = this.state;
    const { classes, products } = this.props;

    if (error) {
      return <Typography>Error: {error}</Typography>;
    } else if (loading || !codes) {
      return <PageLoader />;
    } else {
      return (
        <React.Fragment>
          <PageTitle title="Users Codes">
            <Button variant="contained" size="large" color="secondary" className={classes.submitButton} onClick={this.handleGenerateButtonTap}>+ Generate</Button>
          </PageTitle>

          { codes && Object.keys(codes).map((userId, indx) => {
            const code = codes[userId];
              return (
                <ExpansionPanel expanded={expandedUserID === userId} onChange={this.handleUserSectionExpanded(userId)} key={userId}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div style={{height: "50px", }}>
                      <Typography className={classes.heading} style={{lineHeight:"25px"}}>{`${code.firstname}${code.lastname?code.lastname:''}`}</Typography>
                      <Typography className={classes.secondaryHeading} style={{lineHeight:"25px"}}>
                        Available: <b>{code.available_codes.length}</b> &nbsp; | &nbsp; Redeemed: <b>{code.redeemed_codes.length}</b> &nbsp; | &nbsp; Total: <b>{code.redeemed_codes.length + code.available_codes.length}</b>
                      </Typography>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails style={{display: "flex", flexDirection: "column"}}>

                    <Typography className={classes.secondaryHeading}>
                      {code.id}<br/>
                      {code.email}<br/><br/>
                    </Typography>

                    <Tabs 
                      value={tabIndexes[userId]?tabIndexes[userId]:0}
                      onChange={(event, value)  => { this.setState((state) => ({ tabIndexes: { ...state.tabIndexes, [userId]: value } })) }} 
                      textColor="primary" 
                      indicatorColor="secondary">
                      <Tab label="Available" className={classes.tabItem}/>
                      <Tab label="Redeemed" className={classes.tabItem}/>
                    </Tabs>
                    
                    <div className={classes.separatorLine} />
                    { (!tabIndexes[userId] || tabIndexes[userId] === 0) &&
                      <TabContainer>
                        <Card className={classes.card}>
                          <CardContent>
                            <MUIDataTable title="Available Codes" data={this.tableDataFromCodes(code.available_codes)} columns={availableTableColumns} options={options} />
                          </CardContent>
                        </Card>
                      </TabContainer>}

                      { tabIndexes[userId] === 1 &&
                      <TabContainer>
                        <Card className={classes.card}>
                          <CardContent>
                            <MUIDataTable title="Redeemed Codes" data={this.tableDataFromRedeemedCodes(code.redeemed_codes)} columns={redeemedTableColumns} options={options} />
                          </CardContent>
                        </Card>
                      </TabContainer>}

                  </ExpansionPanelDetails>
                  {/* <Divider />
                  <ExpansionPanelActions>
                    <Button size="small" className={classes.removeButton} onClick={()=>this.showRemoveDialogForSession(userId)}>Remove from Account</Button>
                  </ExpansionPanelActions> */}
                </ExpansionPanel>
            )
          })
          }

          <GenerateCodesDialog open={generateDialogOpen} handleClose={this.handleGenerateDialogClose} handleReload={this.handleGenerateDialogReload} products={products} />
        </React.Fragment>
      );
    }
  }
}

PromoCodes.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  products: state.products.data
});

export default connect(mapStateToProps)(withStyles(styles)(PromoCodes));