import React, { Component } from 'react'

export default class Dashboard extends Component {
  static propTypes = {
    
  }

  render() {
    return (
      <div>
        ---- Dashboard ----
      </div>
    )
  }
}