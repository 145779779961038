import { interfaceConstants } from "../constants";

const initialState = {
  sidebarOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case interfaceConstants.SIDEBAR_TOGGLE:
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen
      };
    case interfaceConstants.SIDEBAR_OPEN:
      return {
        ...state,
        sidebarOpen: true
      };
    case interfaceConstants.SIDEBAR_CLOSE:
      return {
        ...state,
        sidebarOpen: false
      };
    default:
      return state;
  }
};
