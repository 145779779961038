import { productsConstants } from "../constants";

const initialState = {
  data: null,
  dataByID: null,
  error: null,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
  case productsConstants.LOAD_COMPLETED:
    return {
      ...state,
      data: action.data,
      dataByID: action.dataByID,
      error: null,
      loading: false
    };
  case productsConstants.LOAD_FAILED:
    return {
      ...state,
      data: null,
      dataByID: null,
      error: action.error.message,
      loading: false
    };
  case productsConstants.LOADING:
    return {
      ...state,
      loading: true
    };
  default:
    return state;
  }
};
