import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px'
  },
  back: {
    order: -2,
    display: 'flex',
    lineHeight: '2.5rem'
  },
  backLabel: {
    lineHeight: 'inherit'
  },
  backSeparator: {
    width: '1px',
    backgroundColor: theme.palette.primary.light,
    marginRight: '15px',
    marginLeft: '15px',
  },
  title: {
    order: -1,
    flex: 1,
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: '2.5rem'
  },
  actions: {

  }
});

class PageTitle extends Component {

  backComponent(title) {
    const { classes, backAction } = this.props;
    return <div className={classes.back}>
      <div onClick={backAction} style={{cursor: 'pointer'}}>
        <Typography variant="body1" color="primary" className={classes.backLabel}>{title}</Typography>
      </div>
      <div className={classes.backSeparator}></div>
    </div>
  }

  render() {
    const { classes, title, backTitle, children } = this.props;
    return (
      <div className={classes.root}>
      <div style={{display: 'flex'}}>
        {backTitle && this.backComponent(backTitle)}
        <Typography variant="h4" className={classes.title} color="primary">
          {title}
        </Typography>
      </div>
        <div className={classes.actions}>{children}</div>
      </div>
    );
  }
}

PageTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string
};

export default withStyles(styles)(PageTitle);
