import { combineReducers } from 'redux';

import auth from './auth.reducer';
import purchases from './purchases.reducer';
import products from './products.reducer';
import interfaces from './interface.reducer';

const rootReducer = combineReducers({
  auth,
  purchases,
  products,
  interface: interfaces
});

export default rootReducer;