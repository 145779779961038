import api from "../services/api";
import { purchasesConstants } from "../constants";

export function loadPurchases() {
  return async dispatch => {
    dispatch({ type: purchasesConstants.LOADING, loading: true });
    try {
      // console.log("[Purchases]: loading purchases");
      const data = await api.User.purchases();
      // console.log("[Purchases]: ", data);
      dispatch({ type: purchasesConstants.LOAD_COMPLETED, data });
    } catch (err) {
      dispatch({ type: purchasesConstants.LOAD_FAILED, error: err });
    }
  };
}