import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import Table from "../components/Table";
import TableCell from "../components/Table/TableCell";
import TableBody from "../components/Table/TableBody";
import TableHead from "../components/Table/TableHead";
import TableRow from "../components/Table/TableRow";
import TableHeaderRow from "../components/Table/TableHeaderRow";
import TableHeaderCell from "../components/Table/TableHeaderCell";
import PageTitle from "../components/PageTitle";
import dateFormatter from "../services/formatters/date"

const styles = theme => ({

});

class PurchasesList extends Component {
  render() {
    const { /*classes,*/ purchases } = this.props;
    return (
      <React.Fragment>
      <PageTitle title="Purchases" />

      <Table style={{width: "600px"}}>
        <TableHead>
          <TableHeaderRow>
            <TableHeaderCell>Item</TableHeaderCell>
            <TableHeaderCell>Date</TableHeaderCell>
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {purchases.data.map((purchase, indx) => {
            return (
              <TableRow key={indx} style={{cursor:"pointer"}}>
                <TableCell>
                  <img alt={purchase.product_name} src={purchase.product_icon} width="35px" height="35px" style={{verticalAlign:'middle'}} />
                  &nbsp; &nbsp; &nbsp;
                  {purchase.product_name}</TableCell>
                <TableCell>{dateFormatter.formatMYSQLDate(purchase.payment_date)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      </React.Fragment>
    )
  }
}

PurchasesList.propTypes = {
  classes: PropTypes.object.isRequired,
  purchases: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  purchases: state.purchases
});

export default connect(mapStateToProps)(withStyles(styles)(PurchasesList));
