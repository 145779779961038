import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import NavBar from "./NavBar";
import SideBar from "./SideBar";

const styles = theme => ({
  root: {
    display: "flex",
    height: '100%',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,

    marginTop: "64px",
    [theme.breakpoints.up("md")]: {
      marginTop: "80px"
    }
  }
});

class Layout extends React.Component {
  render() {
    const { classes, routesSections, sidebarOpen, sidebarToggleAction, children, auth } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <NavBar sidebarToggleAction={sidebarToggleAction} username={auth && auth.loggedIn && auth.user ? auth.user.email : null} />
        <SideBar
          routesSections={routesSections}
          toggleAction={sidebarToggleAction}
          sidebarOpen={sidebarOpen}
        />
        <main className={classes.content}>
          {children}
        </main>
      </div>
    );
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  routesSections: PropTypes.array.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  sidebarToggleAction: PropTypes.func.isRequired
};

export default withStyles(styles)(Layout);
