import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MUITable from "@material-ui/core/Table";

const styles = theme => ({
    table: {
        backgroundColor: theme.palette.background.paper
    }
});

const Table = (props) => {
    return <MUITable className={props.classes.table} style={props.style}>{props.children}</MUITable>;
}

export default withStyles(styles)(Table);