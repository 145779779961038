import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import withRoot from "../withRoot";

import { connect } from "react-redux";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import Layout from "../components/Layout";
import PageLoader from "../components/PageLoader";
import NoMatch from "./NoMatch";

import { validateAuth } from "../actions/auth.actions";
import { toggleSidebar } from "../actions/interface.actions";
import { loadProducts } from "../actions/products.actions";

import { routesForRole, createSectionsFromRoutes } from "../services/routes";

const styles = theme => ({

});

class App extends React.Component {

  async componentWillMount() {
    await this.props.dispatch(validateAuth());
    await this.props.dispatch(loadProducts());
  }

  render() {
    const { sidebarOpen, auth, products } = this.props;
    if (!auth.loggedIn || products.loading) {
      return <PageLoader />;
    } else {
      const routes = routesForRole(auth.user.role);
      const routesSections = createSectionsFromRoutes(routes);
      return (
        <BrowserRouter>
          <Layout
            routesSections={routesSections}
            auth={auth}
            sidebarOpen={sidebarOpen}
            sidebarToggleAction={() => {
              this.props.dispatch(toggleSidebar());
            }}
          >
            <Switch>
              {routes.map((item) => (
                <Route
                  exact={item.exact}
                  path={item.path}
                  component={item.component}
                  key={item.key}
                />
              ))}
              <Route component={NoMatch} />
            </Switch>
          </Layout>
        </BrowserRouter>
      );
    }
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  products: state.products,
  sidebarOpen: state.interface.sidebarOpen
});

App = withRoot(withStyles(styles, { withTheme: true })(App));
export default connect(mapStateToProps)(App);