import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
  snackbarMessage: {
    color: "white",
    display: "flex",
    alignItems: "center"
  },
  snackbarContent: {
    backgroundColor: "#2E8B57"
  },
  snackbarErrorContent: {
    backgroundColor: "#e74c3c"
  },
  snackbarIcon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing.unit
  }
});

class ErrorSnackbar extends React.Component {
  static defaultProps = {
    duration: 5000,
    anchorOrigin: { vertical: "bottom", horizontal: "center" }
  };
  render() {
    const { classes, open, message, handleClose, duration, anchorOrigin } = this.props;
    return (
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={open}
        autoHideDuration={duration}
        onClose={handleClose}
      >
        <SnackbarContent
          className={classes.snackbarErrorContent}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.snackbarMessage}>
              <CheckCircleIcon className={classes.snackbarIcon} /> {message}
            </span>
          }
          action={[
            <IconButton key="close" aria-label="Close" color="primary" onClick={handleClose} >
              <CloseIcon style={{ fontSize: 20 }} />
            </IconButton>
          ]}
        />
      </Snackbar>
    );
  }
}

ErrorSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  duration: PropTypes.number,
  anchorOrigin: PropTypes.object,
};

export default withStyles(styles)(ErrorSnackbar);