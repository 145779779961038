import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// import Divider from '@material-ui/core/Divider';

import { Link, Route } from "react-router-dom";

const styles = theme => ({
  drawerTitle: {
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: `${theme.sidebar.width}px`,
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: `${theme.sidebar.width}px`
  },
  routerLink: {
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active':  {
        textDecoration: 'none',
    }
  },
  listItem: {
    color: theme.sidebar.itemColor,
    minHeight: '52px',
    borderBottom: `1px solid ${theme.sidebar.bottomBorderColor}`,
    borderLeft: `6px solid transparent`,
    '&:hover': {
      backgroundColor: theme.sidebar.hoverBackgroundColor
    }
  },
  activeListItem: {
    color: theme.sidebar.activeItemColor,
    backgroundColor: theme.sidebar.selectedBackgroundColor,
    minHeight: '52px',
    borderBottom: `1px solid ${theme.sidebar.bottomBorderColor}`,
    borderLeft: `6px solid ${theme.palette.secondary.main}`,
    '&:hover': {
      backgroundColor: theme.sidebar.hoverBackgroundColor
    }
  }
});

const ListItemLink = (props) => {
  const { classes, item, sidebarOpen, toggleAction } = props;
  return (
    <Route
      path={item.path}
      exact={item.exact}
      children={({ match }) => (
        <Link to={item.path} className={classes.routerLink}>
          <ListItem color="primary" className={match ? classes.activeListItem : classes.listItem} button onClick={() => {
            if (sidebarOpen) toggleAction();
          }}>
            <ListItemText primary={item.title} />
          </ListItem>
        </Link>
      )} />
  );
}


class SideBar extends React.Component {

  render() {
    const { classes, routesSections } = this.props;
    const sectionsCount = routesSections ? routesSections.length : 0;
    const drawer = (
      <div>
        <Typography variant="headline" color="primary" className={classes.drawerTitle}>Watusi</Typography>
        
        {routesSections.map((section, indx) => {
          const isLastSection = indx === sectionsCount -1;
          return <React.Fragment key={indx}>
            <List>
              {section.map((route, sectionIndex) => {
                  const isLastRow = sectionIndex === section.length - 1;
                  return <ListItemLink item={route} key={sectionIndex} {...this.props} style={!isLastSection && isLastRow ? {  } : null } />
                })} 
            </List>
            {!isLastSection && <div style={{height: "5px"}} />}
          </React.Fragment>
        })};
      </div>
    );

    return (
      <nav className={classes.drawer}>
        {/* The implementation can be swap with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            open={this.props.sidebarOpen}
            onClose={this.props.toggleAction}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            open
            variant="permanent">
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    );
  }
}

SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  routesSections: PropTypes.array.isRequired,
  toggleAction: PropTypes.func.isRequired
};

export default withStyles(styles)(SideBar);
