const axios = require("axios");

const createAxiosInstance = (baseURL, addHeadersHandler = null, retryRequestHandler = null) => {
  const instance = axios.create({ 
    baseURL: baseURL,
    maxContentLength: 100000000,
    maxBodyLength: 100000000
  });
  
  if (addHeadersHandler) {
    instance.interceptors.request.use(
      config => {
        const additionalHeaders = addHeadersHandler();
        const keys = Object.keys(additionalHeaders);
        keys.forEach(header => {
          config.headers[header] = additionalHeaders[header];
        });
        return config;
      },
      error => Promise.reject(error)
    );
  }

  if (retryRequestHandler) {
    instance.interceptors.response.use(res => res, async (error) => {
      if (await retryRequestHandler(error)) {
        return instance.request(error.config);
      }
      return Promise.reject(error);
    });
  }
  
  const responseBody = res => res.data;
  const errorHandler = err => {
    throw err;
    //console.log('HTTP Request Error: ', err.response && err.response.data ? err.response.data : err.message);
  };
  const requests = {
    del: (url, options = null) => instance.delete(url, options).then(responseBody).catch(errorHandler),
    get: (url, options = null) => instance.get(url, options).then(responseBody).catch(errorHandler),
    put: (url, body, options = null) => instance.put(url, body, options).then(responseBody).catch(errorHandler),
    post: (url, body, options = null) => instance.post(url, body, options).then(responseBody).catch(errorHandler)
  };

  return requests;
};

export default {
  createAxiosInstance,
  axios
};