import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import PageLoader from "../components/PageLoader";

import PurchasesList from "./PurchasesList";
import Purchase from "./Purchase";

import { loadPurchases } from "../actions/purchases.actions";

const styles = theme => ({});

class Purchases extends Component {

  componentDidMount () {
    document.title = "Purchases";
  }

  async componentWillMount() {
    await this.props.dispatch(loadPurchases());
  }

  render() {
    const { match, purchases } = this.props;
    const { error, loading, data } = purchases;

    if (data) {
      return (
        <React.Fragment>
          <Switch>
            <Route path={`${match.path}/:pid`} component={Purchase} />
            <Route path={match.path} exact={true} component={PurchasesList} />
          </Switch>
        </React.Fragment>
      );
    }  else if (error) {
      return <Typography>Error: {error}</Typography>;
    } else if (loading) {
      return <PageLoader />;
    } else {
      return <PageLoader />;
    }
  }
}

Purchases.propTypes = {
  classes: PropTypes.object.isRequired,
  purchases: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  purchases: state.purchases
});

export default connect(mapStateToProps)(withStyles(styles)(Purchases));
