import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import PageTitle from "../components/PageTitle";
import PageLoader from "../components/PageLoader";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import GenerateCodesDialog from '../components/Dialogs/GenerateCodesDialog';

import API from "../services/api";
import dateFormatter from "../services/formatters/date"

import MUIDataTable from "mui-datatables";

const columns = [
 {
  name: "Code",
  options: {
   filter: false,
   sort: false,
  }
 },
 {
  name: "Product",
  options: {
   filter: true,
   sort: false,
  }
 },
 {
  name: "User",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "Redeemed",
  options: {
   filter: true,
   sort: true,
  }
},
{
  name: "Created At",
  options: {
    filter: true,
    sort: true,
  }
},
{
  name: "Desc",
  options: {
    filter: true,
    sort: true,
  }
},
];

const options = {
  //filterType: 'checkbox',
  filterType: 'dropdown',
  responsive: "scroll"
};

const styles = theme => ({
  submitButton: {
    borderRadius: 0,
    textTransform: "none",
    height: "37px",

    margin: theme.spacing.unit,
    marginTop: "0"
  }
});

class PromoCodes extends Component {
  state = {
    codes: null,
    loading: false,
    error: null,
    codesByUser: null,
    expandedUserID: null,
    generateDialogOpen: false
  };

  componentDidMount () {
    document.title = "Promo Codes";
  }

  async componentWillMount() {
    try {
      const codes = await API.Admin.promoCodes();
      const codesByUser = await API.Admin.promoCodesByUser();

      const data = await this.createTableDataFromPromoCodes(codes);
      this.setState({ loading: false, error: null, codes: data, codesByUser });
    } catch (err) {
      this.setState({ loading: false, error: "Promo Codes Loading Failed" });
    }
  }

  async createTableDataFromPromoCodes(codes) {
  //   {
  //     "id": "1C2S-3VIO-547O-WZ8C",
  //     "product_id": 1,
  //     "user_id": "XhN7xpOp0WgfH2YwyFSyaR6NdzM2",
  //     "created_at": "2019-01-19T01:16:50.000Z",
  //     "redeemed": false,
  //     "redeemed_by": null,
  //     "redeemed_at": null,
  //     "desc": null
  // },
    const { productsByID } = this.props;
    const data = codes.map(code => {
      return [ code.id, productsByID[code.product_id].name, code.user_id, code.redeemed?"Yes":"No", 
      dateFormatter.sortableFormattedMYSQLDate(code.created_at),
      code.desc ]
    });
    return data;
  }

  handleUserSectionExpanded = panel => (event, expanded) => {
    this.setState({ expandedUserID: expanded ? panel : false });
  };

  handleGenerateButtonTap = () => {
    this.setState({ generateDialogOpen: true });
  };

  handleGenerateDialogClose = () => {
    this.setState({ generateDialogOpen: false });
  }

  render() {
    const { error, loading, codes, generateDialogOpen } = this.state;
    const { classes, products } = this.props;

    if (error) {
      return <Typography>Error: {error}</Typography>;
    } else if (loading || !codes) {
      return <PageLoader />;
    } else {
      return (
        <React.Fragment>
          <PageTitle title="Promo Codes">
            <Button variant="contained" size="large" color="secondary" className={classes.submitButton} onClick={this.handleGenerateButtonTap}>+ Generate</Button>
          </PageTitle>

          <MUIDataTable title={"Codes"} data={codes} columns={columns} options={options} />
          <GenerateCodesDialog open={generateDialogOpen} handleClose={this.handleGenerateDialogClose} products={products} />
        </React.Fragment>
      );
    }
  }
}

PromoCodes.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  productsByID: state.products.dataByID,
  products: state.products.data
});

export default connect(mapStateToProps)(withStyles(styles)(PromoCodes));