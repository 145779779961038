import React, { Component } from 'react'

export default class NoMatch extends Component {
  static propTypes = {
    
  }

  render() {
    return (
      <div style={{color: 'black'}}>
        Delete
      </div>
    )
  }
}
