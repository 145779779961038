import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorSnackbar from "../Snackbars/ErrorSnackbar";
import API from "../../services/api";

class GenerateCodesDialog extends React.Component {
  
    constructor(props) {
    super(props);
    this.state = this.generateDefaultState();
  }

  generateDefaultState = () => {
    return {
      loading: false,
      email: "",
      numberOfCodes: "1",
      description: "",
      productID: this.props.products && this.props.products.length > 0 ? this.props.products[0].id : null,
      codes: null,
      error: null
    };
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleGenerate = async () => {
    const { email, numberOfCodes, description, productID } = this.state;
    const count = parseInt(numberOfCodes);
    if (!email || email.length === 0) {
      this.setState({ error: "Email can not be empty" });
    } else if (count === 0) {
      this.setState({ error: "Number of codes must be bigger than 0" });
    } else if (productID === "" || productID === null) {
      this.setState({ error: "Product ID can not be empty" });
    } else {
      this.setState({ loading: true });
      const body = {
        user: email,
        count,
        desc: description,
        product_id: productID
      };
      try {
        const res = await API.Admin.generatePromoCodes(body);
        console.log(res);
        this.setState({ loading: false, error: null, codes: res });
      } catch (err) {
        console.log(err);
        console.log(JSON.stringify(err));
        let message = "Code Generation Failed";
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          if (err.response.data.error.description) {
            message = err.response.data.error.description;
          } else if (err.response.data.error.message) {
            message = err.response.data.error.message;
          }
        }
        this.setState({ loading: false, error: message });
      }
    }
  };

  handleClose = () => {
    if (!this.state.loading) {
      this.setState(this.generateDefaultState());
      this.props.handleClose();
    }
  };

  render() {
    const { open, products } = this.props;
    const {
      loading,
      codes,
      error,
      email,
      numberOfCodes,
      description,
      productID
    } = this.state;

    const ErrorBar = (
      <ErrorSnackbar
        open={error != null}
        message={error}
        handleClose={() => {
          this.setState({ error: null });
        }}
        duration={3000}
      />
    );

    if (!codes) {
      return (
        <React.Fragment>
          <Dialog
            open={open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Generate Codes</DialogTitle>
            <DialogContent>
              {/* <DialogContentText>To generate promo codes, enter</DialogContentText> */}
              <TextField
                autoFocus
                disabled={loading}
                id="email"
                label="Email Address"
                type="email"
                value={email}
                onChange={this.handleChange("email")}
                helperText="The account to which the codes belong"
                margin="normal"
                fullWidth
              />
              <br />
              <br />
              <InputLabel htmlFor="product">Product</InputLabel>
              <Select
                disabled={loading}
                value={productID}
                onChange={this.handleChange("productID")}
                inputProps={{ name: "product", id: "product" }}
                fullWidth
              >
                {products &&
                  products.map( (product, idx) => (
                    <MenuItem value={`${product.id}`} key={idx}>{product.name}</MenuItem>
                  ))}
              </Select>
              <TextField
                disabled={loading}
                id="standard-number"
                label="Number of Codes"
                value={numberOfCodes}
                onChange={this.handleChange("numberOfCodes")}
                type="number"
                InputLabelProps={{
                  shrink: true
                }}
                margin="normal"
                fullWidth
              />
              <TextField
                disabled={loading}
                id="email"
                label="Description"
                type="email"
                value={description}
                onChange={this.handleChange("description")}
                margin="normal"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              {loading && (
                <CircularProgress
                  size={27}
                  color="secondary"
                  style={{ marginLeft: "15px" }}
                />
              )}
              <Button
                onClick={this.handleClose}
                color="primary"
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                onClick={this.handleGenerate}
                color="primary"
                disabled={loading}
              >
                Generate
              </Button>
            </DialogActions>
          </Dialog>
          {error && ErrorBar}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Dialog
            open={open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Codes</DialogTitle>
            <DialogContent>
              {codes.map(code => (
                <Typography key={code.id} variant="body1" color="primary">
                  {code.id}
                  <br />
                </Typography>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Done
              </Button>
            </DialogActions>
          </Dialog>
          {error && <ErrorBar />}
        </React.Fragment>
      );
    }
  }
}

GenerateCodesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default GenerateCodesDialog;
