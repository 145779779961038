import axiosAPI from "./api.axios";
import config from "../../config";
import FormData from "form-data";
import cookie from "js-cookie";

const development = true;
const LOGIN_URL = config.LOGIN_URL;
const API_URL = config.API_URL;
const APT_API_URL = config.APT_API_URL;

let currentAccessToken, currentRefreshToken;

const addHeadersHandler = () => ({ Authorization: `Bearer ${currentAccessToken}` });

const retryRequestHandler = async error => {
  if (error.config && error.response && error.response.status === 401) {
    // console.log("Got Authorization Error... will try to refresh access token");
    try {
      const res = await axiosAPI.axios.post(`${API_URL}/auth/refresh`, {
        refresh_token: currentRefreshToken
      });
      const data = res.data;
      if (data && data.access_token) {
        // console.log("Got New Access Token");
        currentAccessToken = data.access_token;
        setCookie("access_token", currentAccessToken);;
        return true;
      }
      // console.log("Did not receive any data or token from refresh");
      window.location = `${LOGIN_URL}?redirect=${window.location.href}`;
      return false;
    } catch (err) {
      // console.log("Refresh Error: ", err);
      window.location = `${LOGIN_URL}?redirect=${window.location.href}`;
      return false;
    }
  } else {
    return false;
  }
};


// main  requests
const authRequests = axiosAPI.createAxiosInstance(API_URL, addHeadersHandler, retryRequestHandler);
const requests = axiosAPI.createAxiosInstance(API_URL);

const Auth = {
  login: (email, password, device) => authRequests.post("/auth/login", { email, password, device, save: device ? true : false }),
  logout: (token) => authRequests.post("/auth/logout", { refresh_token: token }),
  register: (username, email, password) => authRequests.post("/auth/register", { user: { username, email, password } }),
  refresh: (token) => authRequests.post("/auth/refresh", { refresh_token: token })
};

const User = {
  current: () => authRequests.get("/user"),
  purchases: () => authRequests.get("/user/purchases"),
  updateProfile: (firstname, lastname) => authRequests.post("/user/update", { firstname, lastname: lastname && lastname.length > 0 ? lastname: null }),
  updatePassword: (current_password, new_password, logout) => authRequests.post(`/user/update/password`, { current_password, new_password, logout }),
  sessions: (params) => authRequests.get(`/user/sessions`, { params }), // params: os_type, scale
  deleteSession: (sessionId) => authRequests.del(`/user/sessions/${sessionId}`)
};


const Admin = {
  promoCodes: (body = null) => authRequests.post("/admin/promo/available", body),
  promoCodesByUser: () => authRequests.get("/admin/promo/available/user"),
  generatePromoCodes: (body = null) => authRequests.post("/admin/promo/generate", body),
  findUserDetails: (searchBy, searchValue) => authRequests.post("/admin/find/details", { [searchBy]: searchValue }),
}

const Products = {
  getAllProducts: () => requests.get("/products")
}

// apt requests
const aptAuthRequests = axiosAPI.createAxiosInstance(APT_API_URL, addHeadersHandler, retryRequestHandler);
const aptRequests = axiosAPI.createAxiosInstance(APT_API_URL);

const APT = {
  sync: () => aptAuthRequests.get("/packages/sync"),
  
  getSourceInfo: () => aptAuthRequests.get("/packages/source"),

  deletePackageVersion: (pid, version) => aptAuthRequests.del(`/packages/${pid}/${version}`),
  deleteAllPackageVersions: (pid) => aptAuthRequests.del(`/packages/${pid}/all`),

  getAllPackages: () => aptAuthRequests.get("/packages"),
  getPackageLatestVersion: (pid) => aptAuthRequests.get(`/packages/${pid}`),
  getPackageVersion: (pid, version) => aptAuthRequests.get(`/packages/${pid}/${version}`),
  getAllPackageVersions: (pid) => aptAuthRequests.get(`/packages/${pid}/all`),
  getPackagesDownloads: () => aptAuthRequests.get("/packages/downloads"),

  uploadPackage: (filePath) => {
    const data = formDataForFile("deb", filePath);
    return aptAuthRequests.post("/packages", data, { headers: data.getHeaders() });
  },
  uploadChangelogs: (pid, filePath) => {
    const data = formDataForFile("changelogs", filePath);
    return aptAuthRequests.post(`/packages/${pid}/changelogs`, data, { headers: data.getHeaders() });
  },
  uploadDescription: (pid, filePath) => {
    const data = formDataForFile("description", filePath);
    aptAuthRequests.post(`/packages/${pid}/description`, data, { headers: data.getHeaders() });
  },

  getAllChangelogs: (pid) => aptRequests.get(`/packages/${pid}/changelogs`),
  getLatestChangelog: (pid) => aptRequests.get(`/packages/${pid}/changelogs/latest`),
  getVersionChangelog: (pid, version) => aptRequests.get(`/packages/${pid}/changelogs/${version}`),
  getDescription: (pid) => aptRequests.get(`/packages/${pid}/description`),

  getPackageDepictionData: (pid) => aptRequests.get(`/packages/${pid}/depiction`),
};

function formDataForFile(key, filePath) {
  let data = new FormData();
  // data.append(key, fs.createReadStream(filePath));
  return data;
}

// user
let current_user = null;
const getUser = () => {
  return current_user;
};

const setUser = (user) => {
  current_user = user;
};

// cookies
const getCookie = (key) => {
  return cookie.get(key);
};

const setCookie = (key, value, options = {}) => {
  const defaultOptions = { expires: 365 };
  if (!development) {
    defaultOptions.domain = window.location.hostname;
    defaultOptions.secure = true;
  }
  const allOptions = { ...defaultOptions, ...options };
  cookie.set(key, value, allOptions);
};

// export
export default {
  Auth, User, Admin, Products, APT,
  setAccessToken: _token => { currentAccessToken = _token; },
  setRefreshToken: _token => { currentRefreshToken = _token; },
  getAccessToken: () => currentAccessToken,
  getRefreshToken: () => currentRefreshToken,
  setCookie,
  getCookie,
  setUser,
  getUser
};
