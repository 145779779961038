import api from "../services/api";
import { productsConstants } from "../constants";

export function loadProducts() {
  return async dispatch => {
    dispatch({ type: productsConstants.LOADING, loading: true });
    try {
      const data = await api.Products.getAllProducts();

      let dataByID = null;
      if (data) {
        dataByID = {};
        data.forEach(product => {
          dataByID[product.id] = { ...product };
        });
      }

      dispatch({ type: productsConstants.LOAD_COMPLETED, data, dataByID });
    } catch (err) {
      dispatch({ type: productsConstants.LOAD_FAILED, error: err });
    }
  };
}