import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import PageContent from "../components/PageContent";
import PageTitle from "../components/PageTitle";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Moment from 'react-moment';
import API from "../services/api";

const styles = theme => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing.unit * 3,
  },
  progressContainer: {
    marginLeft: theme.spacing.unit * 2,
    
    width: "50px",
    height: "36px",
    display: "flex",
    alignItems: "center"
  },
  button: {
    marginLeft: theme.spacing.unit,
    maxHeight: "36px"
  },
  errorMessageContainer: {
    minHeight: "36px",
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing.unit,
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
  detailText: {
    color: 'gray'
  },
  separatorLine: {
    backgroundColor: theme.palette.third.main,
    height: "1px",
    marginTop: "-1px"
  },
  tabItem: {
    "&:hover": {
      textColor: theme.palette.secondary.main
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});

const searchByFilters = [
  { value: 'email', name: 'Email Address' },
  { value: 'user_id', name: 'User ID' },
  { value: 'paypal_email', name: 'PayPal Email' },
  { value: 'paypal_transaction_id', name: 'Transaction ID' },
  { value: 'payment_id', name: 'Payment ID' }
]

class FindUsers extends Component {
  
  state = {
    searchBy: "email",
    searchValue: "",
    loading: false,
    errorMessage: "",
    searchResult: null,
    expandedUserID: null,
    tabIndexes: {}
  };

  componentDidMount() {
    document.title = "Purchases";
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSearchSubmit = async () => {
    const { searchBy, searchValue } = this.state;
    if (searchBy && searchBy.length > 0 && searchValue && searchValue.length > 0) {
      try {
        this.setState({ loading: true, errorMessage: null });
        const res = await API.Admin.findUserDetails(searchBy, searchValue);
        console.log(res);
        this.setState({ loading: false, searchResult: res });
      } catch (err) {
          console.log(err);
          let message = "User Search Failed";
          if (err && err.response && err.response.data && err.response.data.error) {
              if (err.response.data.error.description) {
                  message = err.response.data.error.description;
              } else if (err.response.data.error.message) {
                  message = err.response.data.error.message;
              }
          }
          this.setState({ loading: false, errorMessage: message });
      }
    } else {
      this.setState({ loading: false, errorMessage: "Search critieria and search value can not be empty" });
    }
  }

  async componentWillMount() {

  }

  render() {
    const { classes } = this.props;
    const { searchBy, searchValue, loading, errorMessage } = this.state;
    return (
      <React.Fragment>
        <PageTitle title="Find Users">
          {/* <Button variant="contained" size="large" color="secondary" className={classes.submitButton} onClick={this.handleGenerateButtonTap}>+ Create</Button> */}
        </PageTitle>

        <PageContent>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={6}>
              <InputLabel>Search by</InputLabel>
              <Select
                id="searchBy"
                name="searchBy"
                label="Search by"
                fullWidth
                value={searchBy}
                onChange={this.handleInputChange}
                disabled={loading}
              >
                {searchByFilters.map(filter => (
                  <MenuItem key={filter.value} value={filter.value}>{filter.name}</MenuItem>  
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="searchValue"
                name="searchValue"
                label="Value"
                value={searchValue}
                onChange={this.handleInputChange}
                disabled={loading}
                required
                fullWidth
              />
            </Grid>
          </Grid>

          <div className={classes.actionsBar}>
            {!loading && errorMessage && errorMessage.length > 0 && <div className={classes.errorMessageContainer}><Typography component="div" color="error">{errorMessage}</Typography></div>}
            {loading && <div className={classes.progressContainer}><CircularProgress className={classes.progress} size={30} color="secondary" /></div> }
            <Button variant="contained" color="secondary" onClick={this.handleSearchSubmit} className={classes.button} disabled={loading}>Search</Button>
          </div>
        </PageContent>

        {this.searchResultsContentComponent()}
        
      </React.Fragment>
    );
  }

  handleUserSectionExpanded = panel => (event, expanded) => {
    this.setState({ expandedUserID: expanded ? panel : false });
  };

  searchResultsContentComponent = () => {
    const { classes } = this.props;
    const { searchResult, expandedUserID, tabIndexes } = this.state;
    if (!searchResult) {
      return null;
    }
    if (searchResult.length === 0) {
      return <PageContent><Typography>No users found</Typography></PageContent>
    }

    return (
        <React.Fragment>
        {searchResult.map((user, indx) => {
          const userId = user.id;
          return <ExpansionPanel expanded={expandedUserID === userId} onChange={this.handleUserSectionExpanded(userId)} key={indx}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div style={{height: "100px" }}>
                <Typography className={classes.heading} style={{lineHeight:"25px"}}>{`${user.firstname}${user.lastname?user.lastname:''}`}</Typography>
                <Typography className={classes.secondaryHeading} style={{lineHeight:"25px"}}>{user.email}</Typography>
                <Typography className={classes.secondaryHeading} style={{lineHeight:"25px"}}>{user.id}</Typography>
                <Typography className={classes.secondaryHeading} style={{lineHeight:"25px"}}><b>{user.purchases.length}</b> purchases &nbsp; | &nbsp; <b>{user.sessions.length}</b> devices</Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{display: "flex", flexDirection: "column"}}>

            <Tabs 
              value={tabIndexes[userId]?tabIndexes[userId]:0}
              onChange={(event, value)  => { this.setState((state) => ({ tabIndexes: { ...state.tabIndexes, [userId]: value } })) }} 
              textColor="primary" 
              indicatorColor="secondary">
              <Tab label="User" className={classes.tabItem}/>
              <Tab label={`Purchases (${user.purchases.length})`} className={classes.tabItem}/>
              <Tab label={`Devices (${user.sessions.length})`} className={classes.tabItem}/>
            </Tabs>
            
            <div className={classes.separatorLine} />
            { (!tabIndexes[userId] || tabIndexes[userId] === 0) &&
              <TabContainer>
                {this.userInfoTabContentFromUser(user)}
              </TabContainer>}

              { tabIndexes[userId] === 1 &&
              <TabContainer>
                {this.purchasesTabContentFromPurchases(user.purchases)}
              </TabContainer>}

              { tabIndexes[userId] === 2 &&
              <TabContainer>
                {this.devicesTabContentFromDevices(user.sessions)}
              </TabContainer>}

            </ExpansionPanelDetails>
          </ExpansionPanel>
        })}
      </React.Fragment>
    );
  }

  userInfoTabContentFromUser = (user) => {
    return <Table padding="none" size="small">
      <TableBody>
        <TableRow>
          <TableCell align="left">User ID</TableCell>
          <TableCell align="left">{user.id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Email Address</TableCell>
          <TableCell align="left">{user.email}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Role ID</TableCell>
          <TableCell align="left">{user.role_id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Max Devices</TableCell>
          <TableCell align="left">{user.max_devices}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Firstname</TableCell>
          <TableCell align="left">{user.firstname}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Lastname</TableCell>
          <TableCell align="left">{user.lastname}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Bio</TableCell>
          <TableCell align="left">{user.bio}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Photo</TableCell>
          <TableCell align="left">{user.photo}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Email Verified</TableCell>
          <TableCell align="left">{user.email_verified}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Disabled</TableCell>
          <TableCell align="left">{user.disabled}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Created At</TableCell>
          <TableCell align="left"><Moment format="D MMM, YYYY, h:mm:ss A">{user.created_at}</Moment></TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Updated At</TableCell>
          <TableCell align="left"><Moment format="D MMM, YYYY, h:mm:ss A">{user.updated_at}</Moment></TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Last Login At</TableCell>
          <TableCell align="left"><Moment format="D MMM, YYYY, h:mm:ss A">{user.last_login_at}</Moment></TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Password Updated At</TableCell>
          <TableCell align="left"><Moment format="D MMM, YYYY, h:mm:ss A">{user.password_updated_at}</Moment></TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Desc</TableCell>
          <TableCell align="left">{user.desc}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  }

  purchasesTabContentFromPurchases = (purchases) => {
    return (
      <List>
        {purchases.map((purchase, idx) => {
          if (!purchase) return null;
          const product = this.getProductFromProductID(purchase.product_id);
          const image = product && product.item && product.item.thumb ? `https://fouadraheb.com/images/items/${product.item.thumb}` : null;
          const paymentMethod = purchase.payment_type === "pp" ? "PayPal" : purchase.payment_type === "promo" ? "Promo" : purchase.payment_type === "cy" ? "Cydia" : purchase.payment_type;
          const transactionID = purchase.payment && purchase.payment.sale_id ? purchase.payment.sale_id : null;
          const payerInfo = purchase.payment && purchase.payment.payer_email ? purchase.payment.payer_email : null;
          return (
            <ListItem style={{paddingLeft: '0px', paddingRight: '0px'}} key={idx}>
              <Avatar style={{backgroundColor: 'white'}}>
                { image ? <img src={image} alt={""} width="27" height="27"/> : <ImageIcon /> }
              </Avatar>
              <ListItemText 
                primary={product.name}
                secondary={
                  <React.Fragment>
                    {paymentMethod}<br/>
                    {purchase.payment_id}<br/>
                    <Moment format="D MMM, YY, hh:mm:ss">{purchase.payment_date}</Moment>
                    {transactionID && <span><br/>{transactionID}</span>}
                    {payerInfo && <span><br/>{payerInfo}</span>}
                  </React.Fragment>
                }
              />
            </ListItem>
          )
        })}
      </List>
    )
  }

  devicesTabContentFromDevices = (devices) => {
    return (
      <List>
        {devices.map((device, idx) => {
          const modelMessage = `${device.device_model} - ${device.device_type}`;
          const osMessage = `${device.os_type} ${device.os_version}`;
          return (
            <ListItem style={{paddingLeft: '0px', paddingRight: '0px'}} key={idx}>
              <ListItemText 
                primary={device.device_name}
                secondary={
                  <React.Fragment>
                    {modelMessage}<br/>
                    {osMessage}<br/>
                    Added <Moment format="D MMM, YY, hh:mm:ss">{device.created_at}</Moment><br/>
                    Last Used <Moment format="D MMM, YY, hh:mm:ss">{device.last_used_at}</Moment>
                  </React.Fragment>
                }
              />
            </ListItem>
          );
        })}
      </List>
    )
  }

  getProductFromProductID = (productID) => {
    const products = this.props.products;
    return products && products.length > 0 ? products.find(product => product.id === productID) : null;
  }

}

function TabContainer(props) {
  return (
    <Typography component="div" style={{ paddingTop: 24 }}>
      {props.children}
    </Typography>
  );
}

FindUsers.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  products: state.products.data
});

export default connect(mapStateToProps)(withStyles(styles)(FindUsers));

/*
[
  {
      "id": "EdJV8bXFb1QL0NiA8zKep9QEo6m1",
      "email": "fouad.raheb@hotmail.com",
      "password": "$2b$10$xmSPCmNjH1sHNIjvTBkYmOmXq2HPg/s.IsZ0KIz0nYipiN2Wpie3q",
      "firebase_salt": null,
      "firstname": "Fouad",
      "lastname": "Raheb",
      "role_id": 1,
      "bio": null,
      "photo": null,
      "max_devices": 8,
      "email_token": null,
      "email_verified": 0,
      "disabled": 0,
      "created_at": "2016-09-30T14:15:00.000Z",
      "updated_at": "2019-02-18T16:43:58.000Z",
      "last_login_at": "2019-05-16T18:24:01.000Z",
      "password_updated_at": "2019-01-11T19:06:52.000Z",
      "desc": null,
      "purchases": [
          {
              "user_id": "EdJV8bXFb1QL0NiA8zKep9QEo6m1",
              "product_id": 1,
              "payment_id": "PAY-0U080417WE004910NLFQLZRQ",
              "payment_type": "pp",
              "payment_date": "2017-07-08T11:07:18.000Z",
              "payment": {
                  "id": "PAY-0U080417WE004910NLFQLZRQ",
                  "description": "Watusi License",
                  "payer_email": "fouad.raheb@live.com",
                  "payer_id": "ZJ5ZBMBGLQXP2",
                  "payer_country": "AE",
                  "payer_method": "paypal",
                  "sale_id": "6Y353125K5802564N",
                  "sale_state": "completed",
                  "sale_amount": 3.5,
                  "sale_fee": 0.49,
                  "sale_currency": "USD",
                  "sale_date": "2017-07-08T11:07:18.000Z"
              }
          },
          {
              "user_id": "EdJV8bXFb1QL0NiA8zKep9QEo6m1",
              "product_id": 2,
              "payment_id": "PAYID-LS4MWRA69N82642361453642",
              "payment_type": "pp",
              "payment_date": "2019-04-18T19:08:52.000Z",
              "payment": {
                  "id": "PAYID-LS4MWRA69N82642361453642",
                  "description": "Stalky License",
                  "payer_email": "fouad.raheb@live.com",
                  "payer_id": "ZJ5ZBMBGLQXP2",
                  "payer_country": "AE",
                  "payer_method": "paypal",
                  "sale_id": "10A90133A0529774E",
                  "sale_state": "completed",
                  "sale_amount": 1.99,
                  "sale_fee": 0.41,
                  "sale_currency": "USD",
                  "sale_date": "2019-04-18T19:14:57.000Z"
              }
          },
          {
              "user_id": "EdJV8bXFb1QL0NiA8zKep9QEo6m1",
              "product_id": 3,
              "payment_id": "PAYID-LTKA2XI9PN82163GY5301337",
              "payment_type": "pp",
              "payment_date": "2019-05-09T11:22:04.000Z",
              "payment": {
                  "id": "PAYID-LTKA2XI9PN82163GY5301337",
                  "description": "OnlineNotify License",
                  "payer_email": "fouad.raheb@live.com",
                  "payer_id": "ZJ5ZBMBGLQXP2",
                  "payer_country": "AE",
                  "payer_method": "paypal",
                  "sale_id": "6U503775VL380921L",
                  "sale_state": "completed",
                  "sale_amount": 1.99,
                  "sale_fee": 0.17,
                  "sale_currency": "USD",
                  "sale_date": "2019-05-09T11:24:50.000Z"
              }
          }
      ],
      "sessions": [
          {
              "id": "RWRKVjhiWEZiMVFMME5pQTh6S2VwOVFFbzZtMV9fMTU0NzY4ODQ5MTM2Mw==",
              "user_id": "EdJV8bXFb1QL0NiA8zKep9QEo6m1",
              "device_name": "Mac OS",
              "device_model": "Chrome",
              "device_type": "71",
              "os_version": "10.14.1",
              "os_type": "macOS",
              "created_at": "2019-01-17T01:28:11.000Z",
              "last_used_at": "2019-05-14T20:02:44.000Z"
          },
          {
              "id": "RWRKVjhiWEZiMVFMME5pQTh6S2VwOVFFbzZtMV9fMTU0ODIzNTU2NzEzMg==",
              "user_id": "EdJV8bXFb1QL0NiA8zKep9QEo6m1",
              "device_name": "Postman",
              "device_model": "iPhone11,2",
              "device_type": "iPhone",
              "os_version": "11.1",
              "os_type": "iOS",
              "created_at": "2019-01-23T09:26:07.000Z",
              "last_used_at": "2019-05-17T09:59:04.000Z"
          }
      ]
  }
]
*/