import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MUITableHeaderRow from "@material-ui/core/TableRow";

const styles = theme => ({
    tableHeaderRow: {
        
    }
});

const TableHeaderRow = (props) => {
    return <MUITableHeaderRow className={props.classes.tableHeaderRow}>{props.children}</MUITableHeaderRow>;
}

export default withStyles(styles)(TableHeaderRow);