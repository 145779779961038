import { authConstants } from "../constants";

const initialState = {
  loggedIn: false,
  user: null
};

export default (state = initialState, action) => {
  switch (action.type) {
  case authConstants.LOGOUT:
    return {
      ...state,
      loggedIn: false,
      user: null,
    };
  case authConstants.UPDATE_LOGGED_IN:
    return {
      ...state,
      loggedIn: action.loggedIn,
      user: action.user
    };
  default:
    return state;
  }
};
