import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import PageTitle from "../components/PageTitle";
import PageLoader from "../components/PageLoader";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import ErrorIcon from '@material-ui/icons/Error';
// import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import DevicePlaceholder from "../resources/device_placeholder.png";

import API from "../services/api";
import dateFormatter from "../services/formatters/date"

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  removeButton: {
    color: theme.palette.error.main
  },
  snackbarMessage: {
    color: "white",
    display: 'flex',
    alignItems: 'center',
  },
  snackbarContent: {
    backgroundColor: "#2E8B57",
  },
  snackbarErrorContent: {
    backgroundColor: "#e74c3c",
  },
  snackbarIcon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
});

class Devices extends Component {
  state = {
    sessions: null,
    loading: false,
    error: null,
    expandedSession: null,
    removeDialogSession: null,
    removeDialogLoading: false,
    showSnackbarSuccessRemove: false,
    showSnackbarErrorRemove: false,
    snackbarErrorMessage: null
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expandedSession: expanded ? panel : false,
    });
  };

  componentDidMount () {
    document.title = "Devices";
  }

  async componentWillMount() {
    try {
      const params = { device_scale: "3" };
      const auth = this.props.auth;
      const os_type = auth && auth.user && auth.user.role !== 1 ? "iOS" : null;
      if (os_type) params.os_type = os_type;

      const sessions = await API.User.sessions(params);
      this.setState({ sessions, loading: false, error: null });
    } catch (err) {
      this.setState({ loading: false, error: "Devices loading failed" });
    }
  }

  render() {
    const { error, loading, sessions, expandedSession, removeDialogSession, removeDialogLoading, showSnackbarSuccessRemove, showSnackbarErrorRemove, snackbarErrorMessage } = this.state;
    const { classes } = this.props;
    const currentDeviceID = API.getRefreshToken();

    if (error) {
      return <Typography>Error: {error}</Typography>;
    } else if (loading || !sessions) {
      return <PageLoader />;
    } else {
      return (
        <React.Fragment>
          <PageTitle title="Devices" />
          
          <div className={classes.root}>
            {sessions.map((session, indx) => {
              const isCurrentSession = currentDeviceID === session.id;
              const deviceTypeString = `${isCurrentSession?"This ":""}${session.device_model_name?session.device_model_name:session.device_model?session.device_model:session.device_type?session.device_type:""}`;
              return (
                <ExpansionPanel expanded={expandedSession === session.id} onChange={this.handleChange(session.id)} key={session.id}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div style={{width: "50px", height: "50px", verticalAlign: "middle"}}>
                      <img height="100%" 
                      src={session.device_images && session.device_images.small_photo_url_3x ? session.device_images.small_photo_url_3x : DevicePlaceholder} 
                      onError={(e)=>{ e.target.onerror = null; e.target.src=DevicePlaceholder }} alt="" />
                    </div>
                    <div style={{height: "50px", }}>
                      <Typography className={classes.heading} style={{lineHeight:"25px"}}>{session.device_name}</Typography>
                      <Typography className={classes.secondaryHeading} style={{lineHeight:"25px"}}>{deviceTypeString}</Typography>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <table style={{border: "none"}}>
                      <tbody>
                        <tr>
                          <td><Typography>Model</Typography></td>
                          <td><Typography style={{color: "#9e9e9e", marginLeft: "30px"}}>{session.device_model}</Typography></td>
                        </tr>
                        <tr>
                          <td><Typography>Version</Typography></td>
                          <td><Typography style={{color: "#9e9e9e", marginLeft: "30px"}}>{session.os_type} {session.os_version}</Typography></td>
                        </tr>
                        <tr>
                          <td><Typography>Added</Typography></td>
                          <td><Typography style={{color: "#9e9e9e", marginLeft: "30px"}}>{dateFormatter.formatMYSQLDate(session.created_at)}</Typography></td>
                        </tr>
                        <tr>
                          <td><Typography>Last Access</Typography></td>
                          <td><Typography style={{color: "#9e9e9e", marginLeft: "30px"}}>{dateFormatter.formatMYSQLDate(session.last_used_at)}</Typography></td>
                        </tr>
                      </tbody>
                    </table>
                  </ExpansionPanelDetails>
                  <Divider />
                  <ExpansionPanelActions>
                    <Button size="small" className={classes.removeButton} onClick={()=>this.showRemoveDialogForSession(session)}>Remove from Account</Button>
                  </ExpansionPanelActions>
                </ExpansionPanel>
              );
            })}
          </div>

          <Dialog
            open={removeDialogSession !== null}
            onClose={removeDialogLoading ? () => {} : this.handleRemoveDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Remove {removeDialogSession ? removeDialogSession.device_name : ""}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Are you sure you want to remove this device from your account?</DialogContentText>
            </DialogContent>
            <DialogActions>
              {removeDialogLoading && <CircularProgress size={27} color="secondary" style={{marginLeft: "15px"}} />}
              <Button onClick={this.handleRemoveDialogClose} color="primary" disabled={removeDialogLoading}>Cancel</Button>
              <Button onClick={this.handleRemoveSession} color="primary" className={classes.removeButton} disabled={removeDialogLoading} autoFocus>Remove</Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
            open={showSnackbarSuccessRemove}
            autoHideDuration={5000}
            onClose={this.handleSnackbarClose}
          >
          <SnackbarContent
                className={classes.snackbarContent}
                aria-describedby="client-snackbar"
                message={
                  <span id="client-snackbar" className={classes.snackbarMessage}>
                    <CheckCircleIcon className={classes.snackbarIcon} /> Device removed successfully!
                  </span>
                }
                action={[
                  <IconButton key="close" aria-label="Close" color="primary" onClick={this.handleSnackbarClose}>
                    <CloseIcon style={{fontSize: 20}} />
                  </IconButton>,
                ]}
              />
        </Snackbar>

        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
            open={showSnackbarErrorRemove}
            autoHideDuration={5000}
            onClose={this.handleErrorSnackbarClose}
          >
          <SnackbarContent
                className={classes.snackbarErrorContent}
                aria-describedby="client-snackbar"
                message={
                  <span id="client-snackbar" className={classes.snackbarMessage}>
                    <CheckCircleIcon className={classes.snackbarIcon} /> {snackbarErrorMessage}
                  </span>
                }
                action={[
                  <IconButton key="close" aria-label="Close" color="primary" onClick={this.handleSnackbarClose}>
                    <CloseIcon style={{fontSize: 20}} />
                  </IconButton>,
                ]}
              />
        </Snackbar>

        </React.Fragment>
      );
    }
  }

  showRemoveDialogForSession = (session) => {
    this.setState({ removeDialogSession: session });
  }

  handleRemoveDialogClose = () => {
    this.setState({ removeDialogSession: null });
  }

  handleRemoveSession = async () => {
    try {
      const sessionToRemove = this.state.removeDialogSession;
      this.setState({ removeDialogLoading: true });
      await API.User.deleteSession(sessionToRemove.id);
      const newSessions = this.state.sessions.filter(session => {
        return session.id !== sessionToRemove.id;
      });
      this.setState({ removeDialogLoading: false, sessions: newSessions, removeDialogSession: null, showSnackbarSuccessRemove: true });
    } catch (err) {
      this.setState({
        removeDialogLoading: false, removeDialogSession: null,
        showSnackbarErrorRemove: true, snackbarErrorMessage: err.message
      });
    }
  }

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ showSnackbarSuccessRemove: false });
  };

  handleErrorSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ showSnackbarErrorRemove: false });
  }
}

Devices.propTypes = {
  classes: PropTypes.object.isRequired,
  sessions: PropTypes.array
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(withStyles(styles)(Devices));
