import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MUITableCell from "@material-ui/core/TableCell";

const styles = theme => ({
    tableCell: {
        color: theme.palette.primary.main,
        fontSize: '1rem'
    }
});

const TableCell = (props) => {
    return <MUITableCell className={props.classes.tableCell} style={props.style} onClick={props.onClick}>{props.children}</MUITableCell>;
}

export default withStyles(styles)(TableCell);